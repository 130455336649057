import React from 'react'
import Layout from 'components/layout'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Button from 'components/button/button'
import Videos from 'components/category/videos'
import { graphql, Link } from 'gatsby'
import Content from 'components/category/content'
import Section from 'components/section'
import H3 from 'components/typography/h3'
import BaseH4 from 'components/typography/h4'
import { Grid, Column } from 'components/grid'
import { GatsbyImage } from 'gatsby-plugin-image'

const items = [
  {
    link: '/stihl/pressure-washers/homeowner-pressure-washers/',
    imageId: 'homeownerPressureWashersImage',
    name: 'Homeowner Pressure Washers',
    alt: 'STIHL Homeowner Pressure Washers',
  },
  {
    link: '/stihl/pressure-washers/professional-pressure-washers/',
    imageId: 'proPressureWashersImage',
    name: 'Professional Pressure Washers',
    alt: 'STIHL Professional Pressure Washers',
  },
  {
    link: '/stihl/pressure-washers/electric-pressure-washers/',
    imageId: 'electricPressureWashersImage',
    name: 'Electric Pressure Washers',
    alt: 'STIHL Electric Pressure Washers',
  },
]

const PressureWashersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage, rb200Image, rb800Image } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Pressure Washers | Hutson Inc</title>
        <meta
          name='description'
          content='However big or small the cleaning job, handle it with a STIHL pressure washer. From light-duty power washers to commercial pressure washers, Hutson has the STIHL for you.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Pressure Washers',
                'item': 'https://www.hutsoninc.com/stihl/pressure-washers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Pressure Washers' />

      <Content>
        <Floater items={floaterItems} />

        <Copy header='About STIHL Pressure Washers'>
          <p>
            However big or small your cleaning job may be, STIHL pressure washers can handle it.
            Hutson carries the full line of STIHL power washers, from the light-duty 2,500 PSI{' '}
            <Link to='/stihl/pressure-washers/homeowner-pressure-washers/rb200/'>RB200 model</Link>{' '}
            to the heavy-duty 4,200 PSI{' '}
            <Link to='/stihl/pressure-washers/professional-pressure-washers/rb800/'>
              RB800 commercial pressure washer
            </Link>
            . STIHL gas pressure washers are split up into two categories: homeowner pressure
            washers (2,500 - 2,700 PSI), and professional pressure washers (3,200 - 4,200 PSI).
            Homeowner models are designed for lighter use, including washing cars, fences,
            driveways, house siding, and lawn mowers. For the best pressure washer features, check
            out the professional models with more power, longer hoses, low oil shutdown protection,
            and ceramic sleeve pistons. All STIHL power washers include standard detergent injector
            systems and easy start pump systems. Save time with pressure washer accessories like
            pivot couplers, rotary surface cleaners, hose and wand extensions, and gutter cleaners.
          </p>
        </Copy>

        <Section>
          <H3>What size pressure washer do you need?</H3>
          <CompareGrid>
            <Column columns='1/2'>
              <GatsbyImage
                image={rb200Image.childImageSharp.gatsbyImageData}
                alt='Man cleaning landscaping with a STIHL RB200 light-duty power washer'
              />
              <H4>Light to medium duty models up to 3,000 PSI</H4>
              <p>
                Ideal for cleaning vehicles, mowers, UTVs, and outdoor patio furniture. Also great
                for removing dirt and pollen from siding, fencing, and driveways.
              </p>
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/stihl/pressure-washers/homeowner-pressure-washers/'
                  color='green'
                  ghost
                >
                  Shop under 3,000 PSI pressure washers
                </Button>
              </ButtonContainer>
            </Column>
            <Column columns='1/2'>
              <GatsbyImage
                image={rb800Image.childImageSharp.gatsbyImageData}
                alt='Man washing an excavator with a STIHL RB800 commercial pressure washer'
              />
              <H4>Heavy duty and commercial models above 3,000 PSI</H4>
              <p>
                Ideal for washing construction equipment, large tractors, and ag equipment. Works
                hard to remove tough, set-in stains from driveways, walkways, decks, and siding. Can
                also clean second-story homes and strip unwanted paint.
              </p>
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/stihl/pressure-washers/professional-pressure-washers/'
                  color='green'
                  ghost
                >
                  Shop over 3,000 PSI pressure washers
                </Button>
              </ButtonContainer>
            </Column>
          </CompareGrid>
        </Section>

        <Promos data={promos} type='STIHL Pressure Washer' />

        <Videos
          type='STIHL Pressure Washer'
          id='wp1gn8GwaQA'
          list='PLUz5fj7f_mw9sKahIm10xl2xlzppfPAYS'
        />
      </Content>
    </Layout>
  )
}

const CompareGrid = styled(Grid)`
  margin-top: 30px;

  p {
    margin: 7px 0 0;
  }

  ${Column}:last-of-type {
    margin-top: 30px;
  }

  @media (min-width: 800px) {
    ${Column}:last-of-type {
      margin-top: 0;
    }
  }
`

const H4 = styled(BaseH4)`
  margin-top: 15px;
  font-weight: bold;
`

const ButtonContainer = styled.div`
  margin-top: 25px;

  a {
    display: inline-block;
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query pressureWashersStihlQuery($category: String = "pressure-washers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/pressure-washers/pressure-washers-bg.jpg" }) {
      ...FullWidthImage
    }
    homeownerPressureWashersImage: file(
      relativePath: { eq: "stihl/pressure-washers/homeowner-pressure-washers.jpg" }
    ) {
      ...FloatingGridImage
    }
    proPressureWashersImage: file(
      relativePath: { eq: "stihl/pressure-washers/professional-pressure-washers.jpg" }
    ) {
      ...FloatingGridImage
    }
    electricPressureWashersImage: file(
      relativePath: { eq: "stihl/pressure-washers/electric-pressure-washers.jpg" }
    ) {
      ...FloatingGridImage
    }
    rb200Image: file(
      relativePath: { eq: "stihl/pressure-washers/stihl-rb200-pressure-washer.jpg" }
    ) {
      ...SharpImage680
    }
    rb800Image: file(
      relativePath: { eq: "stihl/pressure-washers/stihl-rb800-commercial-pressure-washer.jpg" }
    ) {
      ...SharpImage680
    }
  }
`

export default PressureWashersPage
